import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import mediaqueries from '@styles/media';
import Image from '@components/Image';

const logoQuery = graphql`
  fragment LOGO_FLUID on ImageSharpFluid {
    base64
    aspectRatio
    src
    srcSet
    srcWebp
    srcSetWebp
    sizes
  }
  query logos {
    logoDarkMode: file(relativePath: { eq: "images/logo-dark-mode.png" }) {
      regular: childImageSharp {
        fluid(maxWidth: 30, quality: 100) {
          ...LOGO_FLUID
        }
      }
    }
    logoLightMode: file(relativePath: { eq: "images/logo-light-mode.png" }) {
      regular: childImageSharp {
        fluid(maxWidth: 30, quality: 100) {
          ...LOGO_FLUID
        }
      }
    }
  }
`;

const Logo = ({ colorMode = 'light' }: { colorMode?: string }) => {
  const logoQueryResults = useStaticQuery(logoQuery);
  const darkModeLogoSrc = logoQueryResults.logoDarkMode.regular.fluid;
  const lightModeLogoSrc = logoQueryResults.logoLightMode.regular.fluid;

  return (
    <ImageContainer>
      {colorMode === 'dark' ? (
        <Image alt="logo" src={darkModeLogoSrc} />
      ) : (
        <Image alt="logo" src={lightModeLogoSrc} />
      )}
    </ImageContainer>
  );
};

export default Logo;

const ImageContainer = styled.div`
  position: relative;
  width: 30px;
  height: 30px;

  ${mediaqueries.tablet`
    width: 25px;
    height: 25px;
  `}

  ${mediaqueries.phablet`
    width: 25px;
    height: 25px;
  `}
`;
